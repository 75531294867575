import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Intermediate, IntermediateProductsToModify, IntermediateRelationship, IntermediateState, IntermediateType } from '@shared/types';
import { update } from 'lodash';

const initialState: IntermediateState = {
    intermediates: undefined,
    productsToModify: [],
    productGroups: [],
    productGroupOptions: ['None'],
    intermediateTypes: [],
    asOfDate: undefined,
    intermediateSelectedType: undefined,
    intermediatesProducedAt: undefined,
    intermediatesHasInventoryOf: undefined,
    intermediatesProjectedUse: undefined,
    intermediateIsFetching: false,
    terminalOptions: [],
    isOrdersAdmin: false,
    isBlendsAdmin: false,
    email: undefined
};

export const intermediatesSlice = createSlice({
    name: 'intermediates',
    initialState,
    reducers: {
        /** Update the selected terminal on the Terminals page */
        updateIntermediates: (state, { payload }: PayloadAction<Intermediate[] | undefined>) => ({
            ...state,
            intermediates: payload,
        }),
        updateProductToModify: (state, { payload }: PayloadAction<IntermediateProductsToModify[]>) => ({
            ...state,
            productsToModify: payload,
        }),
        /** Update Intermediate Type */
        updateIntermediateTypes: (state, { payload }: PayloadAction<IntermediateRelationship[]>) => ({
            ...state,
            intermediateTypes: payload,
        }),
        updateIntermediateSelectedType: (state, { payload }: PayloadAction<IntermediateType | undefined>) => ({
            ...state,
            intermediateSelectedType: payload,
        }),
        /** Update the selected terminal on the Terminals page */
        updateIntermediatesProducedAt: (state, { payload }: PayloadAction<Intermediate[] | undefined>) => ({
            ...state,
            intermediatesProducedAt: payload,
        }),
        /** Update the selected terminal on the Terminals page */
        updateIntermediatesHasInventoryOf: (state, { payload }: PayloadAction<Intermediate[] | undefined>) => ({
            ...state,
            intermediatesHasInventoryOf: payload,
        }),
        /** Update the selected terminal on the Terminals page */
        updateIntermediatesProjectedUse: (state, { payload }: PayloadAction<Intermediate[] | undefined>) => ({
            ...state,
            intermediatesProjectedUse: payload,
        }),
        /** Update As Of Date */
        updateAsOfDate: (state, { payload }: PayloadAction<string>) => ({
            ...state,
            asOfDate: payload,
        }),
        /** Update Product group */
        updateProductGroups: (state, { payload }: PayloadAction<string[]>) => ({
            ...state,
            productGroups: payload,
        }),
        /** Update Product Group Options */
        updateProductGroupOptions: (state, { payload }: PayloadAction<string[]>) => ({
            ...state,
            productGroupOptions: payload,
        }),
        /** Is Fetching */
        updateIsFetching: (state, { payload }: PayloadAction<boolean>) => ({
            ...state,
            intermediateIsFetching: payload,
        }),
        /**Update Auth */
        updateIsOrdersAdmin: (state, { payload }: PayloadAction<boolean>) => ({
            ...state,
            isOrdersAdmin: payload,
        }),
        /**Update Auth */
        updateIsBlendsAdmin: (state, { payload }: PayloadAction<boolean>) => ({
            ...state,
            isBlendsAdmin: payload,
        }),
        /** Update Email */
        updateEmail: (state, { payload }: PayloadAction<string | undefined>) => ({
            ...state,
            email: payload,
        }),
        updateTerminalOptions: (state, { payload }: PayloadAction<{uuid: string, name: string, abbreviation: string, number: number }[]>) => ({
            ...state,
            terminalOptions: payload,
        }),
    },
});
