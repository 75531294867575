import { AnyObject } from '@fhr/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TagType } from '../tags';
import { DateStringInstance } from '@features/recipes/types';
import { resources } from '@shared/constants';

export const buildDateEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
    saveDates: builder.mutation<
        AnyObject,
        {
            queryName: 'PRODUCES' | 'PROJECTEDPRODUCES';
            recipeId: string;
            terminalId: string;
            dates: DateStringInstance[];
            email: string;
        }
    >({
        query: (payload) => ({
            url: `${resources.DATE}`,
            method: 'POST',
            body: payload,
        }),
        invalidatesTags: [],
    }),
});
