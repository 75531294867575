import { Progress } from '@components/Progress/Progress';
import { Box, Chip, CircularProgress, Stack, Tooltip, Typography, styled } from '@mui/material';
import { useSlice } from '@redux-hooks';
import React, { FC, useEffect } from 'react';
import { Intermediate } from '../../../../types';

interface Props {
  [key: string]: any;
}

const CustomChip = styled(Chip)(() => ({}));
export const ChipViewProducedAt: FC<Props> = (props: Props) => {
  const [intermediatesState] = useSlice('intermediates');
  const [terminalsState] = useSlice('terminals');
  const intermediateData = intermediatesState.intermediatesProducedAt;
  const intermediateIsFetching = intermediatesState.intermediateIsFetching;
  const productGroup = intermediatesState.productGroups;
  const productGroupOptions = intermediatesState.productGroupOptions;
  const terminal = terminalsState.terminal;
  useEffect(() => {
  console.log('intermediatesState', intermediatesState);
  }, [intermediatesState]);
  return (
    <>
      <Progress isFetching={intermediateIsFetching} />
      {intermediateData && intermediateData.length > 0 ? (
        <>
          <Typography variant="subtitle2">{`Products produced at ${terminal?.abbreviation}`}</Typography>
          <Box key={'produced_at'} sx={{ borderBottom: '1px dashed', mb: 1, pb: 1 }}>
            {intermediateData.map((intermediate: Intermediate, index) => {
              const id = intermediate.number + ' ' + intermediate.name;
              return (
                <Tooltip key={`product-${id}`} title={intermediate.number}>
                  <Chip
                    key={`product-${id}`}
                    label={intermediate.abbreviation}
                    color={'primary'}
                    sx={{ margin: '0 10px 10px 0' }}
                  />
                </Tooltip>
              );
            })}
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
